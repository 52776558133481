export const createSelectCallback = (styles) => ({ isActive }) => isActive ?  styles.selected : "";

export const userLink = [
    {
        to: "/app", layout: "/sidebar", children: [
            {to: "/app/dashboard", text: "首页", component: "/dashboard"},
            {to: "/app/dashboard/remit", text: "首页", component: "/dashboard/remit"},
            {to: "/app/sieve/:category/batch", text: "检测", component: '/detection'},
            {to: "/app/sieve/:category/single", text: "单条记录", component : "/record/single"},
            {to: "/app/sieve/:category/batch/create", text: "检测", component: '/detection/create'},
            {to: "/app/sieve/:category/batch/create/fb/:batchId", text: "检测", component: '/detection/create'},
            {to: "/app/record/batch/:tag", text: "多条记录", component : "/record/batch"},
            {to: "/app/record/statistics", text: "统计报表", component : "/record/statistics"},
            {to: "/app/finance/recharge/:type", text: "充值管理", component : "/finance/recharge"},
            {to: "/app/finance/consume/customer", text: "消耗管理", component : "/finance/consume"},
            {to: "/app/tools/shunt", component: "/tools/shunt"},
            {to: "/app/tools/match", component: "/tools/match"},
            {to: "/app/tools/area", component: "/tools/area"},
            {to: "/app/tools/filter", component: "/tools/filter"},
            {to: "/app/tools/create/special", component: "/tools/create0"},
            {to: "/app/tools/create/random", component: "/tools/create1"},
            {to: "/app/notice", component: "/notice"}
        ]
    },
    {to: "/login", component: "/login/user", text: "客户登陆"},
    {to: "/login.html", component: "/login/user", text: "客户登陆"},
    {to: "/console/login.html", component: "/login/user", text: "客户登陆"},
    {to: "/anonymous", component: "/anonymous", text: "匿名操作"}
]

export const adminLink = [
    {
        to: "/admin", layout: "/sidebar", children: [
            {to: "/admin/dashboard", text: "首页", component: "/dashboard"},
            {to: "/admin/sieve/:category/batch", text: "检测", component: '/detection'},
            {to: "/admin/sieve/:category/single", text: "检测", component: '/record/single'},
            {to: "/admin/record/single/", text: "单条记录", component : "/record/single"},
            {to: "/admin/record/batch/:status", text: "多条记录", component : "/record/batch"},
            {to: "/admin/record/statistics", text: "统计报表", component : "/record/statistics"},
            {to: "/admin/record/api/statistics", text: "通道统计", component : "/channel/api_statistics"},
            {to: "/admin/tenant/list", text: "租户管理", component : "/user/list"},
            {to: "/admin/agent/list", text: "代理商管理", component : "/user/list"},
            {to: "/admin/customer/list", text: "客户管理", component : "/user/list"},
            {to: "/admin/customer/authentication", text: "客户认证", component : "/user/authentication"},
            {to: "/admin/customer/login/log", text: "登录日志", component : "/user/login"},
            {to: "/admin/channel/api", text: "接口管理", component : "/channel/api"},
            {to: "/admin/channel/store", text: "本地仓库", component : "/channel/store"},
            {to: "/admin/channel/dataset", text: "仓库数据", component : "/channel/dataset"},
            {to: "/admin/channel/upkeep", text: "仓库维护", component : "/channel/upkeep"},
            {to: "/admin/channel/search", text: "仓库查询", component : "/channel/search"},
            {to: "/admin/product/channel", text: "接口配置", hide: true, component : "/channel/config"},
            {to: "/admin/product/price", text: "价格配置", component : "/product/price"},
            {to: "/admin/product/list", text: "产品列表", component : "/product/list"},
            {to: "/admin/customer/product/price", text: "产品配置", component : "/product/price"},
            {to: "/admin/channel/config", text: "通道配置", component : "/channel/config"},
            {to: "/admin/:role/recharge/:type", text: "充值管理", component : "/finance/recharge"},
            {to: "/admin/:role/consume", text: "消耗管理", component : "/finance/consume"},
            {to: "/admin/customer/give", text: "赠送管理", component : "/finance/give"},
            {to: "/admin/customer/remit", text: "客户汇款", component : "/finance/remit"},
            {to: "/admin/setting/account", text: "账号管理", component : "/setting/account"},
            {to: "/admin/setting/sms/template", text: "短信模板", component : "/setting/sms/template"},
            {to: "/admin/setting/sms/record", text: "短信记录", component : "/setting/sms/record"},
            {to: "/admin/setting/website", text: "网站设置", component : "/setting/website"},
            {to: "/admin/setting/pay", text: "支付设置", component : "/setting/pay"},
            {to: "/admin/setting/employee", text: "客服管理", component : "/setting/employee"},
            {to: "/admin/setting/package", text: "充值套餐", component : "/setting/package"},
            {to: "/admin/website/tools", text: "网站工具", component : "/setting/tools"},
            {to: "/admin/website/news", text: "新闻动态", component : "/setting/news"},
            {to: "/admin/agent/return/list", text: "返佣记录", component: "/finance/return"}
        ]
    },
    {to: "/login", component: "/login/user", text: "客户登陆"},
    {to: "/login.html", component: "/login/user", text: "客户登陆"},
    {to: "/console/login.html", component: "/login/user", text: "客户登陆"},
    {to: "/system/login", component: "/login", text: "系统登陆"},
    {to: "/agent/login", component: "/login", text: "代理商登陆"},
    {to: "/tenant/login", component: "/login", text: "租户管理员登陆"},
    {to: "/console/register.html", component: "/register/user", text: "客户登陆"},
    {to: "/register", component: "/register/user", text: "登陆"},
    {to: "/register.html", component: "/register/user", text: "登陆"}
]
