import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import PageLoading from "../components/loading/PageLoading";
import RequireAuth from "../components/auth/RequireAuth";

export const fallbackConfig = {
   fallback : <PageLoading title="页面加载中" />
};

export const RedirectIndex = ({path}) => {
   return <Navigate to={path} replace={true} />
}

export const createComponent = (path) => {
   if(!path.startsWith("/")){
       path = "/" + path
   }
   return loadable(()=>pMinDelay(import(`../pages${path}/index`),200), fallbackConfig);
}

export const createLayout = (path) => {
   return loadable(()=>pMinDelay(import(`../layouts${path}/index`),200), fallbackConfig);
}

const RootLayout = () => {
    return (
        <Outlet />
    )
}

const createRoute = (nav) => {
   let route = { path: nav.to }
   if(nav.children && nav.children.length > 0){
      let layout = <Outlet />;
      if(nav.layout){
         let LayoutComp = createLayout(nav.layout)
         layout = <LayoutComp />
      }
      route.element = layout;
      route.children = nav.children.filter(item=> item != null).map(childItem=>{
         return createRoute(childItem)
      })
      //如果如果子导航的第一个链接不是父链接
      if(nav.to !== route.children[0].path) {
         route.children.unshift({
            index: true,
            element: <RedirectIndex path={route.children[0].path} />
         })
      }else{
         delete route.children[0].path;
         route.children[0].index = true;
      }
   }else{
      let Component = createComponent(nav.component || nav.to);
      if(nav.public){
         route.element = <Component />
      }else {
         route.element = <RequireAuth><Component/></RequireAuth>
      }
   }
   return route;
}

const createRoutes = () => {
    const link = fetchLink();
    let routes = Array.isArray(link) ? link.filter(item=> item != null).map(item=>createRoute(item)) : [];
    return [
        {
          path: "/",
          element: <RootLayout />,
          children: routes
        }
    ]
}

export const createRoutesWithLink = (links) => {
    let routes = Array.isArray(links) ? links.filter(item=> item != null).map(item=>createRoute(item)) : [];
    return [
        {
            path: "/",
            element: <RootLayout />,
            children: routes
        }
    ]
}

export const createRoutesWithNoLogin = () => {
    return [
        {
            path: "/",
            element: <RootLayout />,
            children: [
                {to: "/login", component: "/login/user", text: "客户登陆"},
                {to: "/anonymous", component: "/anonymous", text: "匿名检测"},
                {to: "/login.html", component: "/login/user", text: "客户登陆"},
                {to: "/console/login.html", component: "/login/user", text: "客户登陆"},
                {to: "/system/login", component: "/login", text: "系统登陆"},
                {to: "/agent/login", component: "/login", text: "代理商登陆"},
                {to: "/tenant/login", component: "/login", text: "租户管理员登陆"},
                {to: "/register", component: "/register/user", text: "登陆"},
                {to: "/register.html", component: "/register/user", text: "登陆"},
                {to: "/console/register.html", component: "/register/user", text: "客户登陆"},
                {to: "/signup/:status", component: "/register/confirm"}
            ].map(item=>createRoute(item))
        }
    ]
}

export const fetchLink = () => {
    return require(`../config/link.js`).default;
}

export const getLayoutLink = (path, layout, links) => {
   if(!links){
       links = fetchLink()
   }
   if(links) {
       for (let i = 0; i < links.length; i++) {
           let item = links[i];
           if (!item || path.indexOf(item.to) < 0) {
               continue;
           }
           if (item.layout && item.layout === layout) {
               return item.children ? item.children.filter(item => item != null && !item.hide).map(item => ({
                   to: item.to.startsWith("/") ? item.to : "/" + item.to,
                   icon: item.icon,
                   text: item.text,
                   spliter: item.spliter,
                   hide: item.hide
               })) : [];
           } else if (item.children) {
               return getLayoutLink(path, layout, item.children);
           }
       }
   }
   return [];
}

export default createRoutes;
