import {ADMIN_DASHBOARD_LINK, USER_DASHBOARD_LINK} from "../config/link";
import {isAgentUser, isSuperSystemUser, isSystemUser} from "./user";

export const navigateToLogin = (forceUser = false, forceTenant = false, forceSystem = false) => {
    if(window.location.pathname.startsWith("/app")){
        forceUser = true;
    }
    const host = window.location.host;
    if(host.startsWith("system")){
        forceSystem = true;
    }
    if(host.indexOf("tenant") >= 0){
        forceTenant = true;
    }
    forceUser = true;
    if(forceUser){
       window.location.href = "/login";
       return;
    }else if(forceTenant){
       window.location.href = "/tenant/login";
       return;
    }else if(forceSystem){
       window.location.href = "/system/login";
       return;
    }
    window.location.href = isSuperSystemUser() ? "/system/login" : isSystemUser ? "/tenant/login" : isAgentUser() ? "/agent/login" : "/login";
}

export const navigateToRegister= () => {
    window.location.href = "/register"
}

export const navigateToDashboard = (isUser = false) => {
    window.location.href = isUser ? USER_DASHBOARD_LINK : ADMIN_DASHBOARD_LINK;
}
